body{
  background-color: #fff;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  color: #666666;
  overflow-x: hidden;
  margin-bottom: 25px;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 98vh;
}

#wol-navbar {
  border-bottom: 2px solid #F4F7FF;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-brand img { width: 75%;}

.wol-container { padding-top: 50px; }

footer {
  margin-top: auto;
}

h2 {color: #1F2A66; font-size: 1.6em; font-weight: 600;}
h3 {color: #666666; font-size: 1.5em; font-weight: 500;}
h4 {color: #960F0F; font-size: 1.25em;}
h5 {color: #777777; font-size: 1.2em;}

#wol-navbar .dropdown-item {padding-left: 1.5em;}
#navbar-links {padding-left: .32em;}
#navbar-links a {
  color: #666666;
  font-weight: 500;
  font-size: 1em;
  line-height: 3.125em;
  padding-left: 0;
  margin-right: .6em;
}
.navbar-toggler-icon {background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {background-color: #F4F7FF;}

.job-title-link {color: #1F2A66;}
.job-title-link a {color: #1F2A66;}
.job-title-link a:hover {color: #1F2A66;}

a:hover {text-decoration: underline;}
hr {border-bottom: 1px solid #F4F7FF;}

.wol-form {
  background-color: #F4F7FF;
  padding: 50px;
}

.wol-form .form-group {
  margin-bottom: 65px;
}

.wol-form .form-label {
  color: #666666;
  font-weight: 600;
}

#application-container .form-group{
  margin-bottom: 60px;
}

.candidate-application-section {
  padding: 25px 50px 25px 30px;
  background-color: #F4F7FF;
  margin-bottom: 25px;
}

#application-container {margin-top:35px;}
#application-container h5 {font-size:18px; color:#666666; font-weight: 500;}
#application-container h4 {font-size: 20px; color: #666666;}
#application-container h2 {font-size: 27px;}
#application-container form label {font-size: 16px;}
.application-steps-content {margin-left: 23px; margin-top: 25px;}
.application-steps-check-container {margin-right: 15px;}
.application-steps-button {margin-top: -10px;}
.application-steps-message {margin-bottom: 16px; font-size: 16px;}
.application-state-tag {
  margin: 40px 0 25px 0;
  display: flex;
  align-items: center;
}
.application-state-tag * {margin: 0 0.5em 0 0;}
.application-state-tag p {margin: 0; font-size: 14px;}
.application-state-tag .badge {font-size: 13px;}
.application-state-tag .badge-ligth {background-color: #E0E8FD;}

.candidate-profile-form-section {background-color: #F4F7FF; padding: 35px;}
.candidate-profile-form-section small {color: #666666; font-size: 14px;}
#profile-container .nav-item { margin-bottom: .9em; }
.profile-title {
  color: gray;
  font-size: 36px;
  padding-top: 50px;
  padding-bottom: 0px;
  padding-left: 35px;
}

.form-check [type=checkbox] {
  width: 25px;
  height: 25px;
  background: #333;
  border-radius: 50px;
  position: relative;
}

.password-hide {
  -webkit-text-security: disc;
  border: none;
}

.password-show {
  -webkit-text-security: none;
  border: none;
}

.custom-password-input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.password-hide:focus,
.password-show:focus,
.password-button:focus {
  box-shadow: none;
}

.custom-password-focus {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 0.2rem rgb(31 42 102 / 25%);
  border-color: #4054c5;
}

.error-message {
  color: #d9534f;
  padding: .5em .2em;
  height: 1em;
  position: absolute;
  font-size: .8em;
}
.error {border: 2px solid #d9534f;}

#candidate-jobs-table { margin-top: 50px;}

.candidate-jobs-td {padding-top: 30px !important; padding-bottom: 30px !important;}

.custom-search-input {
  border: 1px solid #ced4da;
  margin: 10px 0;
  padding-left: 10px;
  border-radius: 1rem;
}

.search-input {
  border: none;
}

.search-input:focus,
.search-button:focus  {
  box-shadow: none;
}

.custom-search-focus {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 0.2rem rgb(31 42 102 / 25%);
  border-color: #4054c5;
}

.search-button {
  border-radius: 0 1rem 1rem 0;
  padding-right: 16px;

}
.search-button:hover,
.search-button:focus  {
  background-color: #1F2A66;
  color: white;
}

.job-item {
  border: none;
  margin: 35px 0 0 0;
  word-break: keep-all;
  background-color: #E0E8FD;
  padding: 10px 40px 10px 10px;
  border-radius: 6px;
}

.job-item .options {
  display: flex;
  justify-content: flex-end;
  margin: 4px 0;
}

.main-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.8em;
}
.main-info p {margin-bottom: 0;}

.job-images {
  max-width: 200px;
  max-height: 200px;
}

.job-list-item-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.works-module-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.icon {
  background-color: unset;
  color: #666666;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  transition: 0.15s ease-in-out;
  margin-left: 8px;
}

.icon:hover {
  color: #F4F7FF;
  background-color: #666666;
}

.edit-form-options{
  display: flex;
  justify-content: space-evenly;
}

#salaries-cl-container h3 {font-size: 1.2em; color:#191919; font-weight: 600;}
#salaries-cl-container table { font-size: .8em;}
#salaries-cl-container li { font-style: 'italic';}

#show-me-the-money-container li { margin-bottom: 5px; font-size: 17px;}
#show-me-the-money-container h5 { font-weight: 600;}
#show-me-the-money-container .tab-content { padding: 25px; }

@media (prefers-reduced-motion: no-preference) {
}

@media only screen and (min-width: 1600px) {
  #wol-navbar { max-width: 1850px; padding-left: 150px; padding-right: 145px; }
  .wol-container { max-width: 1850px; padding-left: 155px; padding-right: 155px;}
  .container-center {width: 60%;}
}

@media only screen and (max-width: 1600px) {
  #wol-navbar { max-width: 1597px; padding-left: 150px; padding-right: 145px; }
  .wol-container { max-width: 1850px; padding-left: 155px; padding-right: 155px;}
  .container-center {width: 90%;}
}

@media only screen and (max-width: 1200px) {
  #wol-navbar { max-width: 1197px; padding-left: 90px; padding-right: 80px; }
  .wol-container { max-width: 1197px; padding-left: 90px; padding-right: 90px;}
  .container-center {width: 90%;}
}

@media only screen and (max-width: 992px) {
  #wol-navbar { max-width: 989px; padding-left: 60px; padding-right: 60px; }
  .wol-container { max-width: 989px; padding-left: 60px; padding-right: 60px;}
  .container-center {width: 90%;}
}

@media only screen and (max-width: 768px)  {
  #wol-navbar { max-width: 760px; padding-left: 40px; padding-right: 40px; }
  .wol-container { max-width: 760px; padding-left: 40px; padding-right: 40px;}
  .container-center {width: 98%;}

  .info-xs-hidden {display: none;}
  .job-list-item-buttons {flex-direction: row; justify-content: space-evenly;}
  .job-list-item-buttons a {width: 50%;}
}

@media only screen and (max-width: 576px) {
  #wol-navbar { max-width: 573px; padding-left: 25px; padding-right: 25px; }
  .wol-container { max-width: 573px; padding-left: 25px; padding-right: 25px;}
  .container-center {width: 98%;}
}

/* @media only screen and (max-height: 750px) {
  .footer {display: none;}
} */
